import React from "react";
import "./../../scss/styles/experience.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faHandHoldingMedical,
  faHospital,
  faLocationDot,
  faClock,
  faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";

const Experience = () => {
  return (
    <section className="experience">
      <h1 className="title">Experience</h1>
      <div className="content">
        <div className="tab">
          <div className="tab-title">
            <FontAwesomeIcon icon={faBriefcase} />
            <h2>Work</h2>
          </div>
          <div className="tab-content">
            <div className="item">
              <h3>Dental Surgeon</h3>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faHospital} />
                  <span>Bhaktapur Heart & Diabetes Center</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>
                    Suryabinayak Chowk, Suryabinayak, Bhaktapur, Nepal
                  </span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faClock} />
                  <span>Aug 2021 - Present</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3>Dental Surgeon</h3>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faHospital} />
                  <span>Clinic One</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>Ramdevi Complex, Suryabinayak, Bhaktapur, Nepal</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faClock} />
                  <span>Feb 2021 - Present</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3>Dental Surgeon</h3>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faHospital} />
                  <span>Care Dental Home</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>Star Mall, Putalisadak, Kathmandu, Nepal</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faClock} />
                  <span>Jul 2021 - Present</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="tab">
          <div className="tab-title">
            <FontAwesomeIcon icon={faHandHoldingMedical} />
            <h2>Volunteer</h2>
          </div>
          <div className="tab-content">
            <div className="item">
              <h3>Weeklong Dental Camp</h3>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faHospital} />
                  <span>
                    Kantipur Dental Teaching Hospital And Research Center
                  </span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>Syangja, Nepal</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faClock} />
                  <span>Feb 2016</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3>Dental Camp for Students and Staff</h3>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faHospital} />
                  <span>
                    Kantipur Dental Teaching Hospital And Research Center
                  </span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>
                    Kantipur Dental Teaching Hospital And Research Center
                  </span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faClock} />
                  <span>2020</span>
                </li>
              </ul>
            </div>
            <div className="item">
              <h3>Dental Camp for Students and Staff</h3>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faHospital} />
                  <span>
                    Kantipur Dental Teaching Hospital And Research Center
                  </span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>Kathmandu Vidya Kunja School, Tokha, Kathmandu</span>
                </li>
                <li>
                  <FontAwesomeIcon icon={faClock} />
                  <span>2020</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="alt-icon">
        <FontAwesomeIcon icon={faUserDoctor} />
      </div>
    </section>
  );
};

export default Experience;
