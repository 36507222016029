import Experience from "./components/homepage/Experience";
import Footer from "./components/homepage/Footer";
import Hero from "./components/homepage/Hero";
import Interests from "./components/homepage/Interests";
import Participation from "./components/homepage/Participation";
import Services from "./components/homepage/Services";
import Testimonials from "./components/homepage/Testimonials";

function App() {
  return (
    <section className="App">
      <Hero />
      <Services />
      <Experience />
      <Participation />
      <Testimonials />
      <Interests />
      <Footer />
    </section>
  );
}
export default App;
