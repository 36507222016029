import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setShowCreditsDialog } from "../../store/appSlice";
import "./../../scss/styles/footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopyright,
  faEnvelope,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagramSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import CurveSVG from "../svg/CurveSVG";
import Credits from "../homepage/Credits";

const Footer = () => {
  const dispatch = useDispatch();
  const showCreditsDialog = useSelector((state) => state.app.showCreditsDialog);
  return (
    <section className="footer">
      <div className="social-media">
        <FontAwesomeIcon icon={faLinkedin} />
        <FontAwesomeIcon icon={faFacebook} />
        <FontAwesomeIcon icon={faInstagramSquare} />
      </div>
      <div className="enquiry">
        <h3>For business enquiries</h3>
        <div className="email">
          <FontAwesomeIcon icon={faEnvelope} /> <p>kritirai506@gmail.com</p>
        </div>
      </div>
      <div>
        <p>
          <FontAwesomeIcon icon={faCopyright} /> 2023. Designed and Developed by
          TeamPSR
        </p>
      </div>
      <div className="credits">
        <button onClick={(e) => dispatch(setShowCreditsDialog(true))}>
          <FontAwesomeIcon icon={faGlobe} /> Website Credits
        </button>
      </div>
      <CurveSVG />
      {showCreditsDialog && <Credits />}
    </section>
  );
};

export default Footer;
