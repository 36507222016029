import React from "react";
import "./../../scss/styles/testimonials.scss";

import AvatarPrashandip from "./../../images/avatar-prashandip.png";
import AvatarKriti from "./../../images/avatar-kriti.png";
import AvatarPoornima from "./../../images/avatar-poornima.png";

const Testimonials = () => {
  return (
    <section className="testimonials">
      <h1 className="title">Testimonials</h1>
      <div className="content">
        <div className="card">
          <img src={AvatarPrashandip} alt="P" />
          <h3>Prashandip Limbu</h3>
          <h4>Software Engineer</h4>
          <hr />
          <p>
            We've been friends since we were kids and never had I imagined that
            I'd one day get my teeth fixed from her. I'd love to pinpoint flaws
            about her work, but sadly, I can't find any, apart from her
            beautifully distracting cuteness. 😊
          </p>
        </div>
        <div className="card">
          <img src={AvatarKriti} alt="P" />
          <h3>Kriti Thapa</h3>
          <h4>Software Engineer</h4>
          <hr />
          <p>
            It's been a couple of years that I've been visiting Dr. Rai for
            consultations and dental services, and I am fully content. She is
            very meticulous in her work, is very patient, and provides detailed
            check-ups and consultations to her patients.
          </p>
        </div>
        <div className="card">
          <img src={AvatarPoornima} alt="P" />
          <h3>Poornima Bajracharya</h3>
          <h4>Software Engineer</h4>
          <hr />
          <p>
            I appreciate Dr. Kriti's detail-orientated narrative of the
            diagnosis on every case. She is good at comforting her patients.
            Thank you for your great service. 🙂
          </p>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
