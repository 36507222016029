import React from "react";
import "./../../scss/styles/hero.scss";
import ProfilllePic from "./../../images/profile-pic.png";
import CurveSVG from "../svg/CurveSVG";

const Hero = () => {
  return (
    <section className="hero">
      <div className="intro">
        <h1 className="title">Dr. Kriti Rai</h1>
        <p>
          My interest led me to select dentistry, and I enjoy what I do very
          much. I look forward to providing excellent dental care and enhancing
          smiles.
        </p>
        <p>
          I strive to deliver pain-free treatments, making your dental
          experience as comfortable as possible.
        </p>
      </div>
      <div className="profile-pic">
        <img src={ProfilllePic} alt="PP" />
      </div>
      <CurveSVG />
    </section>
  );
};

export default Hero;
