import React from "react";
import { useDispatch } from "react-redux";
import { setShowCreditsDialog } from "../../store/appSlice";
import "./../../scss/styles/credits.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFontAwesome,
  faGlobe,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import ArtThumbnail from "./../../images/thumbnails/art.jpg";
import FoodThumbnail from "./../../images/thumbnails/food.jpg";
import MeditationThumbnail from "./../../images/thumbnails/meditation.jpg";
import ReadingThumbnail from "./../../images/thumbnails/reading.jpg";
import ServicesThumbnail from "./../../images/thumbnails/services-bg.jpg";
import TravelThumbnail from "./../../images/thumbnails/travel.jpg";

import DrKritiAvatar from "./../../images/avatar-drkriti.jpg";
import PrashandipAvatar from "./../../images/avatar-dev-prashandip.jpg";
import RomanAvatar from "./../../images/avatar-roman.jpg";
import SabinaAvatar from "./../../images/avatar-sabina.jpg";

const Credits = () => {
  const dispatch = useDispatch();
  return (
    <section
      className="credits"
      id="CREDITS"
      onClick={(e) => {
        e.target.id === "CREDITS" && dispatch(setShowCreditsDialog(false));
      }}
    >
      <div className="wrapper">
        <button
          className="close"
          onClick={(e) => dispatch(setShowCreditsDialog(false))}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <div className="content">
          <div className="item">
            <h1 className="title">Administrator</h1>
            <hr />
            <div>
              <img src={DrKritiAvatar} alt="avatar" />
              <div>
                <h3>Dr. Kriti Rai</h3>
                <h4>Owner & Administrator</h4>
                <a
                  href="https://drkriti.com.np/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faGlobe} />
                  <span>drkriti.com.np</span>
                </a>
              </div>
            </div>
            <hr />
          </div>
          <div className="item">
            <h1 className="title">Developer</h1>
            <hr />
            <div>
              <img src={PrashandipAvatar} alt="avatar" />
              <div>
                <h3>Prashandip Limbu</h3>
                <h4>Design Head</h4>
                <a
                  href="https://prashandip.com.np/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faGlobe} />
                  <span>prashandip.com.np</span>
                </a>
              </div>
            </div>
            <hr />
            <div>
              <img src={SabinaAvatar} alt="avatar" />
              <div>
                <h3>Sabina Timilsina</h3>
                <h4>Project Manager</h4>
                <a
                  href="https://www.sabinatimilsina.com.np/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faGlobe} />
                  <span>sabinatimilsina.com.np</span>
                </a>
              </div>
            </div>
            <hr />
            <div>
              <img src={RomanAvatar} alt="avatar" />
              <div>
                <h3>Roman Karki</h3>
                <h4>Development Head</h4>
              </div>
            </div>
            <hr />
          </div>
          <div className="item">
            <h1 className="title">
              <FontAwesomeIcon icon={faFontAwesome} /> Icons from{" "}
              <a
                href="https://fontawesome.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                fontawesome.com
              </a>
            </h1>
          </div>
          <div className="item">
            <h1 className="title">
              Images from{" "}
              <a
                href="https://www.pexels.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                pexels.com
              </a>
            </h1>
            <hr />
            <div>
              <img src={ServicesThumbnail} alt="thumbnail" />
              <div>
                <a
                  href="https://www.pexels.com/photo/metal-dentist-instruments-for-treatment-and-inspection-7787977/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Metal dentist instruments for treatment and inspection
                </a>
                <div>
                  <span>by</span>
                  <a
                    href="https://www.pexels.com/@babydov/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ivan Babydov
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <img src={TravelThumbnail} alt="thumbnail" />
              <div>
                <a
                  href="https://www.pexels.com/photo/back-view-of-a-woman-walking-towards-the-famous-bali-handara-gate-2499699/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Back View of a Woman Walking Towards the Famous Bali Handara
                  Gate
                </a>
                <div>
                  <span>by</span>
                  <a
                    href="https://www.pexels.com/@stijn-dijkstra-1306815/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Stijn Dijkstra
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <img src={ArtThumbnail} alt="thumbnail" />
              <div>
                <a
                  href="https://www.pexels.com/photo/person-sketching-cat-398257/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Person Sketching Cat
                </a>
                <div>
                  <span>by</span>
                  <a
                    href="https://www.pexels.com/@lum3n-44775/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lum3n
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <img src={MeditationThumbnail} alt="thumbnail" />
              <div>
                <a
                  href="https://www.pexels.com/photo/woman-practicing-yoga-3822625/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Woman Practicing Yoga
                </a>
                <div>
                  <span>by</span>
                  <a
                    href="https://www.pexels.com/@elly-fairytale/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Elina Fairytale
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <img src={ReadingThumbnail} alt="thumbnail" />
              <div>
                <a
                  href="https://www.pexels.com/photo/a-woman-reading-a-book-with-her-dog-5255154/"
                  target="_blank"
                  rel="noreferrer"
                >
                  A Woman Reading a Book With Her Dog
                </a>
                <div>
                  <span>by</span>
                  <a
                    href="https://www.pexels.com/@samson-katt/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Samson Katt
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <img src={FoodThumbnail} alt="thumbnail" />
              <div>
                <a
                  href="https://www.pexels.com/photo/rice-with-zucchini-soft-boiled-egg-and-parsley-in-green-ceramic-plate-1410235/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Rice With Zucchini, Soft Boiled Egg, and Parsley in Green
                  Ceramic Plate
                </a>
                <div>
                  <span>by</span>
                  <a
                    href="https://www.pexels.com/@lum3n-44775/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lum3n
                  </a>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Credits;
