import React from "react";

import "./../../scss/styles/interests.scss";

import TravelPic from "./../../images/travel.jpg";
import ArtPic from "./../../images/art.jpg";
import MeditationPic from "./../../images/meditation.jpg";
import ReadingPic from "./../../images/reading.jpg";
import FoodPic from "./../../images/food.jpg";

const Interests = () => {
  return (
    <section className="interests">
      <h1 className="title">Interests</h1>
      <div className="content">
        <div className="card">
          <div className="image">
            <img src={TravelPic} alt="Travel" />
            <h2>Travel</h2>
          </div>
          <p>
            I’m all about learning new cultures, lifestyles, foods etc. It
            refreshes my soul and gives me joy.
          </p>
        </div>
        <div className="card">
          <div className="image">
            <img src={ArtPic} alt="Art" />
            <h2>Art</h2>
          </div>
          <p>
            I’m not a great artist but I try my level best. I do it only when
            I’m totally free.
          </p>
        </div>
        <div className="card">
          <div className="image">
            <img src={MeditationPic} alt="Meditation" />
            <h2>Meditation</h2>
          </div>
          <p>
            Meditation has changed me as a person, and made me more calm and
            focused.
          </p>
        </div>
        <div className="card">
          <div className="image">
            <img src={ReadingPic} alt="Reading" />
            <h2>Reading</h2>
          </div>
          <p>
            I’m open to reading all kinds of books; autobiography or
            work-related. I always want to keep my brain occupied.
          </p>
        </div>
        <div className="card">
          <div className="image">
            <img src={FoodPic} alt="Food" />
            <h2>Food</h2>
          </div>
          <p>
            I love food hunting. I always like to try new cuisines. I’m a food
            lover and I may open my food blog sooner or later.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Interests;
