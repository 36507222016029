import React from "react";
import "./../../scss/styles/services.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCrown,
  faFaceLaughBeam,
  faTooth,
  faUserDoctor,
  faScrewdriverWrench,
  faTeeth,
} from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <section className="services">
      <div className="blur-layer"></div>
      <h1 className="title">Services</h1>
      <div className="content">
        <div className="item">
          <FontAwesomeIcon icon={faCrown} />
          <h2>Crown & Bridge</h2>
        </div>
        <div className="item">
          <FontAwesomeIcon icon={faFaceLaughBeam} />
          <h2>Cosmetic Dentistry</h2>
        </div>
        <div className="item">
          <FontAwesomeIcon icon={faTooth} />
          <h2>Root Canal Therapy</h2>
        </div>
        <div className="item">
          <FontAwesomeIcon icon={faUserDoctor} />
          <h2>Pulpectomy</h2>
        </div>
        <div className="item">
          <FontAwesomeIcon icon={faScrewdriverWrench} />
          <h2>Atraumatic Restoration</h2>
        </div>
        <div className="item">
          <FontAwesomeIcon icon={faTeeth} />
          <h2>Subgingival Scaling</h2>
        </div>
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
      </div>
      {/* <div>
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
        <hr />
        <div className="service">
          <FontAwesomeIcon icon={faCrown} />
          <h2>Crown & Bridge</h2>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faFaceLaughBeam} />
          <h2>Cosmetic Dentistry</h2>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faTooth} />
          <h2>Root Canal Therapy</h2>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faUserDoctor} />
          <h2>Pulpectomy</h2>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faScrewdriverWrench} />
          <h2>Atraumatic Restoration</h2>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faTeeth} />
          <h2>Subgingival Scaling</h2>
        </div>
      </div> */}
    </section>
  );
};

export default Services;
