import React from "react";

const CurveSVG = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 2000 1000"
      width="100%"
      className="curve"
      preserveAspectRatio="none"
    >
      <path
        d="M2000 1000L2000-8.13364e-13C2000-8.13364e-13 1784.56 752.193 1403.84 745.736C1023.12 739.278 931.008 45.0528 553.915 48.0203C176.822 50.9878 0 500 0 500L0 1000L2000 1000Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
    </svg>
  );
};

export default CurveSVG;
