import React from "react";
import "./../../scss/styles/participation.scss";

import Sag2019Logo from "./../../images/sag2019.png";
import WorkshopLogo1 from "./../../images/workshop-logo-1.png";
import WorkshopLogo2 from "./../../images/workshop-logo-2.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTooth, faMedal } from "@fortawesome/free-solid-svg-icons";

const Participation = () => {
  return (
    <section className="participation">
      <h1 className="title">Participation</h1>
      <div className="content">
        <div className="card">
          <img src={Sag2019Logo} alt="SAG 2019" />
          <h2>SAG 2019 Medical Team</h2>
          <p>
            I was both anxious and eager because it was a completely new
            experience for me. I was proud to be able to represent myself as a
            Nepali medical team. The employees, athletes, and the public were
            all given complimentary dental exams by our team.
          </p>
        </div>
        <div className="card">
          <div className="workshop-logos">
            <img src={WorkshopLogo1} alt="Logo 1" />
            <img src={WorkshopLogo2} alt="Logo 2" />
          </div>
          <h2>Oral Medicine Workshop</h2>
          <p>
            This was the first time that an oral medicine conference had been
            conducted in Nepal, and I was fortunate to be able to take part in
            it. The subjects presented were amazing. I was exposed to oral
            medication a lot, and I really enjoyed it.
          </p>
        </div>
      </div>
      <div className="tooth-icon">
        <FontAwesomeIcon icon={faTooth} />
      </div>
      <div className="alt-icon">
        <FontAwesomeIcon icon={faMedal} />
      </div>
    </section>
  );
};

export default Participation;
